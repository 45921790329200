import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import { HashRouter as Router } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

class SallaSetupWizard extends HTMLElement {
  connectedCallback() {
    // const root = document.createElement('div');
    // this.appendChild(root);
    const apiUrl = this.getAttribute('apiUrl');
    const token = this.getAttribute('token');
   
    
    ReactDOM.render(<React.StrictMode>
      <Router>
        <App apiUrl={apiUrl} token={token}/>
      </Router>
    </React.StrictMode>, this);
  }
}
customElements.define('setup-wizard', SallaSetupWizard);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
