import StepWizard from "react-step-wizard";
import Nav from './Nav';
import WizardStep from './WizardStep';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Steps(props) {
    const { app, steps, sallaLogo, externalLogo, token} = props;
  
    const transitions = {
        enterRight: `animated enterRight`,
        enterLeft: `animated enterLeft`,
        exitRight: `animated exitRight`,
        exitLeft: `animated exitLeft`,
        intro: `animated intro`,
    }
    const n = 10;
 
    return (
        <div>
            <div className="lg:h-screen h-auto py-0 flex justify-center items-center">
                <div className="w-screen lg:w-354 overflow-hidden">
                    <div className="bg-white lg:px-9 lg:py-9 px-4 py-4 rounded-none rounded shadow-none lg:shadow-lg min-w-full">
                        {steps.length ? <StepWizard isLazyMount nav={<Nav />} transitions={transitions}>
                            {steps.map((step, index) => <WizardStep app={app} key={index} step={step} sallaLogo={sallaLogo} externalLogo={externalLogo} token={token}/>)}
                        </StepWizard> :
                            <div>
                                <h2 className="mt-3 mb-3"><Skeleton direction='rtl' inline={true} width={80} height={25} style={{ display: 'block', margin: 'auto' }} />    </h2>
                                <Skeleton direction='rtl' width={100} height={10} inline={true} style={{ display: 'block', margin: 'auto' }} />
                                <h2 className="mt-3"><Skeleton direction='rtl' inline={true} width={150} height={25} style={{ display: 'block', margin: 'auto' }} />    </h2>
                                <p className="mt-3"><Skeleton direction='rtl' width={300} height={20} style={{ display: 'block', margin: 'auto' }} /></p>
                                <div className="border rounded mt-9 p-1 md:p-5 ">
                                    <div className="border rounded text-right p-3 flex items-center justify-start w-full">
                                        <i className="sicon-search  relative top-2px"></i>
                                        <div className="w-full">

                                            <Skeleton direction='rtl' inline={true} width={'90%'} height={15} style={{ display: 'block', marginRight: '10px', width: '100%' }} />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start mt-4">
                                        <div className="w-1/2 flex">
                                            <Skeleton direction='rtl' inline={true} width={30} height={30} style={{ display: 'block' }} />
                                            <p className="mr-2 mt-2 w-full"><Skeleton direction='rtl' inline={true} width={'90%'} height={20} style={{ display: 'block' }} /></p>
                                        </div>
                                        <div className="w-1/2 flex">
                                            <Skeleton direction='rtl' inline={true} width={30} height={30} style={{ display: 'block' }} />
                                            <p className="mr-2 mt-2 w-full"><Skeleton direction='rtl' inline={true} width={'90%'} height={20} style={{ display: 'block' }} /></p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-wrap scrollable h-45vh overflow-auto">
                                        <div className="spinner"></div>
                                        <p className="text-sm text-gray-400 mt-3">جاري إستيراد البيانات...</p>
                                    </div>
                                </div>
                                <div className='hidden md:flex md:flex-row  flex-col-reverse  items-center justify-between mt-8'>
                                    <Skeleton direction='rtl' inline={true} width={80} height={35} style={{ display: 'block', margin: 'auto' }} />
                                    <Skeleton direction='rtl' inline={true} width={80} height={35} style={{ display: 'block', margin: 'auto' }} />
                                </div>
                                <div className='block md:hidden md:flex-row  flex-col-reverse  items-center justify-between mt-8 w-full'>
                                    <div className="mb-3">
                                        <Skeleton direction='rtl' inline={true} width={'90%'} height={35} style={{ display: 'block', margin: 'auto' }} />
                                    </div>
                                    <div>
                                        <Skeleton direction='rtl' inline={false} width={'90%'} height={35} style={{ display: 'block', margin: 'auto' }} />
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
