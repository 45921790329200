import React, { useState, useEffect, useRef } from 'react'
import AsyncSelect from 'react-select/async';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import 'react-loading-skeleton/dist/skeleton.css'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom'
export default function WizardStep(props) {
    const navigate = useNavigate()
    // Define state variables
    const { id = 1 } = useParams()
    const { app, step, nextStep, sallaLogo, externalLogo, token, goToStep } = props;
    const mounted = useRef(false)
    const [sallaArray, setSallaArray] = useState([])
    const [externalArray, setExternalArray] = useState([])
    const [combinedArray, setCombinedArray] = useState([])
    const [currentCombinedArray, setCurrentCombinedArray] = useState([])
    const [count, setCount] = useState({
        prev: 0,
        next: 50
    })
    const [hasMore, setHasMore] = useState(true);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    function applyFilter(arr) {
        return arr.filter(item => {
            return (item.salla.name || item.external.name) ? ((item?.salla?.name || '').toLowerCase().includes(filter.toLowerCase()) || (item?.external?.name || '').toLowerCase().includes(filter.toLowerCase())) : ''
        })
    }
    function getCurrentStepTitleInArabic() {
        switch (step.item_type) {
            case 'branches':
                return 'فروع'
            case 'products':
                return 'منتجات'
            case 'categories':
                return ' تصنيفات'
            case 'customers':
                return ' عملاء'
            default: return ''
        }
    }
    const getMoreData = () => {
        if (filter) {
            const filteredArray = applyFilter(combinedArray)
            if (currentCombinedArray.length === filteredArray.length) {
                setHasMore(false);
                return;
            }
            setTimeout(() => {
                if (mounted.current) {
                    setCurrentCombinedArray(filteredArray.concat(filteredArray.slice(count.prev + 50, count.next + 50)))
                }
            }, 1000)
            setCount((prevState) => ({ prev: prevState.prev + 50, next: prevState.next + 50 }))
        } else {
            if (currentCombinedArray.length === combinedArray.length) {
                setHasMore(false);
                return;
            }
            setTimeout(() => {
                if (mounted.current) {
                    setCurrentCombinedArray(currentCombinedArray.concat(combinedArray.slice(count.prev + 50, count.next + 50)))
                }
            }, 1000)
            setCount((prevState) => ({ prev: prevState.prev + 50, next: prevState.next + 50 }))
        }
    }
    function getCombinedArray(salla, external) {
        let combinedArray = [];
        for (let i = 0; i < salla.length; i++) {
            combinedArray.push({
                salla: salla[i],
                external: salla[i].linked_id ? (external.find((item) => item.id === salla[i].linked_id) || { id: null, name: null }) : { id: null, name: null },
                id: uuidv4()
            })
            // if (!salla[i]) {
            //     combinedArray.push({ salla: { id: null, name: null }, external: external[i], id: uuidv4() })
            // } else if (!external[i] || !external[i].linked) {
            //     combinedArray.push({ salla: salla[i], external: { id: null, name: null }, id: uuidv4() })
            // } else {
            //     combinedArray.push({
            //         salla: salla[i],
            //         external: external.find((item) => item.id === salla[i].linked_id),
            //         id: uuidv4()
            //     })
            // }
        }

        console.log(combinedArray);
        return combinedArray;
    }
    function searchItems(e) {
        const query = e.target.value || '';
        setFilter(query)
        setCount({ prev: 0, next: 50 })
        const filteredArray = query ? combinedArray.filter(item => {
            return (item.salla.name || item.external.name) ? ((item?.salla?.name || '').toLowerCase().includes(query.toLowerCase()) || (item?.external?.name || '').toLowerCase().includes(query.toLowerCase())) : ''
        }) : combinedArray
        setCurrentCombinedArray(filteredArray.slice(0, 50))
        if (filteredArray.length) {
            setHasMore(true);
        } else {
            setHasMore(false);
        }
    }
    function handleNextStep() {
        setSubmitting(true)
        setError('')
        let match = [];
        if (combinedArray.length) {
            for (let i = 0; i < combinedArray.length; i++) {
                match.push({ id_salla: combinedArray[i].salla.id, id_external: combinedArray[i].external.id })
            }
        }
        axios.defaults.headers.common['Authorization'] = `${token}`;
        axios.post(step.submit, { items: match, type: step.item_type }).then(res => {
            console.log((app.prefix + `/final`).substring(1));
            if (step.is_last) {
                navigate((app.prefix + `/final`).substring(1))
            } else {
                navigate((app.prefix + `/steps/${Number(id) + 1}`).substring(1))
            }
            setSubmitting(false)
        }).catch(e => {
            console.error(e);
            setSubmitting(false)
            if (e.response && e.response?.data?.redirectUrl) {
                window.location.href = e.response.data.redirectUrl;
            } else {
                setError('حدث خطأ ما يرجى تحديث الصفحة')
            }
        })
    }
    function filterOptions(value) {
        return externalArray.filter(item => !item.selected).map(item => ({ label: item.name, value: item.id })).filter((i) =>
            i.label.toLowerCase().includes(value.toLowerCase())
        );
    }
    function loadOptions(inputValue, callback) {
        setTimeout(() => {
            callback(filterOptions(inputValue))
        }, 1000)
    }
    function makeRowEditable(id) {
        let toUpdate = combinedArray.findIndex((item) => {
            return item.id === id;
        });
        const updatedArray = [...combinedArray]
        updatedArray[toUpdate].external.editing = true
        setCombinedArray(updatedArray)
    }
    function handleInputChange(newValue) {
        return newValue;
    };

    function handleOnOptionChange(e, i, parentId) {
        if (!e) {
            let indexToRemove = combinedArray.findIndex((item) => {
                return item.id === parentId;
            });
            const updatedCombinedArray = [...combinedArray]
            const updatedExternal = JSON.parse(JSON.stringify([...externalArray]))
            let removedItem = JSON.parse(JSON.stringify(updatedCombinedArray[indexToRemove].external))
            let removedItemIndexInExternal = externalArray.findIndex((item) => {
                return item.id === removedItem.id;
            });
            updatedExternal[removedItemIndexInExternal].selected = false
            updatedCombinedArray[indexToRemove].external = { id: null, name: null }
            setCombinedArray(updatedCombinedArray)
            setExternalArray(updatedExternal)
            return;
        }
        let prevItem = JSON.parse(JSON.stringify(currentCombinedArray[i]))
        let toUpdate = combinedArray.findIndex((item) => {
            return item.id === parentId;
        });
        let prevIndex = prevItem.external.id ? externalArray.findIndex((item) => {
            return item.id === prevItem.external.id;
        }) : -1
        let externalItemIndex = externalArray.findIndex((item) => {
            return item.id === e.value;
        });
        const updatedExternalArray = JSON.parse(JSON.stringify([...externalArray]))
        const updatedArray = [...combinedArray]
        let selectedItem = updatedExternalArray[externalItemIndex]
        if (prevIndex !== -1) {
            updatedExternalArray[prevIndex].selected = false
        }
        updatedExternalArray[externalItemIndex].selected = true
        if (selectedItem) {
            updatedArray[toUpdate].external = selectedItem
        }
        setCombinedArray(updatedArray)
        setExternalArray(updatedExternalArray)
    }
    useEffect(() => {
        goToStep(id)
        if (!mounted.current) {
            setLoading(true)
            axios.defaults.headers.common['Authorization'] = `${token}`;
            const sallaURL = axios.get(step.salla.url)
            const externalURL = axios.get(step.external.url)
            axios.all([
                sallaURL,
                externalURL
            ]).then(
                axios.spread((...responses) => {
                    const salla = responses[0].data.data
                    const external = responses[1].data.data
                    setSallaArray(salla)
                    const externalArrayCopy = external
                    setExternalArray(externalArrayCopy)
                    const combinedArrayCopy = getCombinedArray(salla, externalArrayCopy)
                    setCombinedArray(combinedArrayCopy)
                    setCurrentCombinedArray(combinedArrayCopy.slice(count.prev, count.next))
                    setLoading(false)
                })
            ).catch((e) => {
                // todo :: cleanup
                setCurrentCombinedArray([]);
                setExternalArray([]);
                setCombinedArray([]);
                setSallaArray([]);
                setLoading(false)
                if (e.response && e.response?.data?.redirectUrl) {
                    window.location.href = e.response.data.redirectUrl;
                } else {
                    setError('حدث خطأ ما يرجى تحديث الصفحة')
                }
            })

            mounted.current = true;
        }
        return () => {
            mounted.current = false;
        }
    }, [id])
    return (
        <div>

            <h2 className="text-center mt-4 text-lg">{step.title}</h2>
            <p className="text-gray-400 text-center text-sm">{step.description}</p>
            {step.note && <div className="flex p-4 mb-4 text-sm text-yellow-700 justify-center" role="alert">
                <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 ml-1 relative top-0.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Info</span>
                <div>
                    {step.note}.
                </div>
            </div>}

            <div className="border rounded mt-9 p-2 md:p-5">
                <div className="border rounded text-right p-2 flex items-center justify-between">
                    <i className="sicon-search text-gray-400 relative top-2px"></i>
                    <input className="border-0 mr-3 w-full outline-none text-sm" value={filter} onChange={searchItems} placeholder={step['search-placeholder']} type="search" />
                </div>
                <div className="flex items-center justify-start my-6">
                    <div className="w-1/2 flex">
                        <img className="w-30px h-30px rounded" src={sallaLogo} alt="" />
                        <p className="mr-2 font-bold">{step.salla.title}</p>
                        {(externalArray.length > sallaArray.length || sallaArray.length > externalArray.length) && <span className='mr-2'>({sallaArray.length})</span>}
                    </div>
                    <div className="w-1/2 flex">
                        <img className="w-30px h-30px rounded" src={externalLogo} alt="" />
                        <p className="mr-2 font-bold">{step.external.title}</p>
                        {(externalArray.length > sallaArray.length || sallaArray.length > externalArray.length) && <span className='mr-2'>({externalArray.length})</span>}
                    </div>
                </div>
                {/* for responsive md:h-45vh h-36vh */}
                {
                    <div>
                        {!loading ?
                            currentCombinedArray.length ?
                                <InfiniteScroll
                                    dataLength={currentCombinedArray.length}
                                    next={getMoreData}
                                    className=" scrollable "
                                    height={'45vh'}
                                    hasMore={hasMore}
                                    inverse={false}
                                    loader={<div className='basis-full w-full text-center'><div className="loader mx-auto"></div></div>}
                                >
                                    {currentCombinedArray.map((item, i) => {
                                        return (
                                            <div className="flex items-center mt-4" key={uuidv4()}>
                                                <div className="w-1/2 mb-4 text-ellipsis overflow-hidden whitespace-nowrap break-words">
                                                    <p className="text-sm flex items-center"> {item.salla.name}</p>
                                                </div>
                                                <div className="w-1/2 mb-4">
                                                    <div className="" >
                                                        <div className="flex items-center relative">
                                                            <i className="sicon-keyboard_arrow_left text-gray-200 text-xl relative top-1"></i>
                                                            <AsyncSelect
                                                                className="react-select-container w-full mr-2 md:mr-7"
                                                                cacheOptions
                                                                isClearable={true}
                                                                classNamePrefix="react-select"
                                                                onChange={(e) => handleOnOptionChange(e, i, item.id)}
                                                                loadOptions={loadOptions}
                                                                defaultValue={item.external.id ? { label: item.external.name, value: item.external.id } : null}
                                                                onInputChange={handleInputChange}
                                                                defaultOptions={externalArray.filter(item => !item.selected).map(item => ({ label: item.name, value: item.id }))}
                                                                loadingMessage={() => 'جاري البحث..'}
                                                                noOptionsMessage={() => 'لا توجد نتائج'}
                                                                placeholder="أختر"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </InfiniteScroll>
                                :
                                <>
                                    {
                                        ((externalArray.length && !sallaArray.length) || (sallaArray.length && !externalArray.length)) && step.item_type !== 'branches' ?
                                            <div className="flex flex-col items-center justify-center flex-wrap  h-45vh overflow-auto">
                                                <i className='sicon-swap-stroke text-gray-400 text-40px'></i>
                                                <h3 className='my-3'>نسخ ال{getCurrentStepTitleInArabic()}</h3>
                                                <p className='text-sm text-gray-400'>سيتم نسخ {getCurrentStepTitleInArabic()} فودكس <br /> الى متجرك لإتمام عملية الربط</p>
                                            </div>
                                            :
                                            <div className="flex flex-col items-center justify-center flex-wrap  h-45vh overflow-auto">
                                                <i className='sicon-page-search-alt text-gray-400 text-40px'></i>
                                                <h3 className='my-3'>لا توجد بيانات للربط</h3>
                                                <p className='text-sm text-gray-400'> يرجى اعادة المحاولة</p>
                                            </div>
                                    }
                                </>
                            :
                            <div className="flex flex-col justify-center items-center flex-wrap scrollable h-45vh overflow-auto">
                                <div className="spinner"></div>
                                <p className="text-sm text-gray-400 mt-3">جاري إستيراد البيانات...</p>
                            </div>
                        }
                    </div>
                }
            </div>
            {!!error && <p className='bg-red-50 p-1.5 pb-3 pr-3 rounded text-red-400 mt-2 text-sm'>{error}</p>}
            <div className='flex md:flex-row flex-col-reverse  items-center justify-between mt-4'>
                {step.skippable && !step.is_last ?
                    <div className='w-full md:w-auto mt-4 md:mt-0'>
                        <button className={`bg-white border  w-full md:w-auto px-7 py-3 text-primary text-sm border-primary shdadow-btn rounded ${loading ? "opacity-50 cursor-not-allowed" : ""}`} onClick={nextStep}>تخطي</button>
                    </div> : null}
                {step.is_last
                    ? <button className={`
                        ${!submitting
                            ? 'bg-secondary px-4 mr-auto  w-full md:w-28 py-3 text-primary text-sm shadow-btn rounded'
                            : 'bg-secondary px-8 mb-4 md:mb-0 w-full md:w-28 py-3 mr-auto text-white text-sm shadow-btn rounded'}
                            ${loading ? "opacity-50 cursor-not-allowed" : ""}
                    `}
                        onClick={handleNextStep} disabled={loading}>
                        {
                            !submitting
                                ? 'اتمام الربط'
                                : <div className="loader small mx-auto"></div>
                        }
                    </button>
                    :
                    <button
                        className={`flex justify-center bg-secondary pr-4 mr-auto w-full md:w-auto pl-2 pt-2 pb-3 text-primary shadow-btn rounded ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                        disabled={loading}
                        onClick={handleNextStep}>
                        <span className='mr-auto md:mr-0'> التالي </span>
                        {!submitting
                            ? <i className='sicon-keyboard_arrow_left text-2xl text-primary mr-auto md:mr-5 relative top-px'></i>
                            : <div className="loader small mr-auto md:mr-5 relative top-1.5"></div>}
                    </button>
                }
            </div>
        </div>
    )
}
