import React, { useEffect, useState } from 'react';


export default function FinalPage(props) {
    const [timeLeft, setTimeLeft] = useState(5);
    const { endLabel, endRedirectLabel, redirectURL } = props.data;
    useEffect(() => {
        if (timeLeft === 0) {
            window.location.href = redirectURL;
            setTimeLeft(null)
        }
        if (!timeLeft) return;
        const intervalId = setInterval(() => {

            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft])

    return (
        <div className="h-screen  flex justify-center items-center">

            <div className="w-11/12 lg:w-96">
                <div className="bg-white lg:px-9 px-3 pt-12 pb-12 rounded-lg shadow-basic min-w-full text-center3">
                    <i className='sicon-check-circle text-center block text-85px text-secondary'></i>
                    <h1 className='text-primary font-bold text-xl text-center mt-8'>{endLabel}</h1>
                    <p className="text-gray-500 text-center text-sm mt-3">{endRedirectLabel}</p>
                    <p className="text-primary text-center text-sm mt-3">{timeLeft}</p>
                </div>
            </div>
        </div>
    )
}
