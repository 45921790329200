import React from 'react';
const Nav = (props) => {
    const { goToStep, currentStep, totalSteps } = props;
    const dots = [];
    for (let i = 1; i <= totalSteps; i += 1) {
        const isActive = currentStep === i;
        dots.push((
            <span
                key={`step-${i}`}
                className={`dot ${isActive ? 'active' : ''}`}
                onClick={() => goToStep(i)}
            ></span>
        ));
    }
    return (
        <div className='pointer-events-none'>
            <p className="text-gray-300 text-center">{currentStep} / {totalSteps} </p>
            <div className="nav">{dots}</div>
        </div>
    );
};
export default Nav;