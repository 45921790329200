
import { useEffect, useState } from 'react';
import FirstPage from '../Wizard/firstPage';
import Steps from '../Wizard/steps';
import Final from '../Wizard/finalPage';
import React from "react";
import axios from 'axios'
import {
    Route,
    Routes
} from "react-router-dom";
import { useRef } from 'react';

function App(props) {
    const [appInfo, setAppInfo] = useState({})
    const [steps, setSteps] = useState({})
    const [appReady, setAppReady] = useState(false)
    const [sallaLogo, setSallaLogo] = useState('')
    const [externalLogo, setExternalLogo] = useState('')
    const { token, tokenHeader, apiUrl } = props
    const interval = useRef(null)
    const [endPageData, setEndPageData] = useState({})

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `${token}`;

        interval.current = setInterval(function () {
            if (!appReady) {
                axios.get(`${apiUrl}`).then(res => {
                    setSteps(res.data.data.steps)
                    setAppInfo(res.data.data.app)
                    setAppReady(res.data.data.app_ready)
                    setSallaLogo(res.data.data.app.salla_logo)
                    setExternalLogo(res.data.data.app.external_logo)
                    setEndPageData({endLabel: res.data.data.app['end-label'], endRedirectLabel: res.data.data.app['end-redirect-label'], redirectURL: res.data.data.app_redirect_url})
                })
            }
        }, 3000);
        if (appReady) {
            clearInterval(interval.current)
        }
        
        return () => clearInterval(interval.current);


    }, [appReady])
    return (
        <Routes>
            <Route exact path={appInfo.prefix} element={<FirstPage app={appInfo} appReady={appReady}/>} />
            <Route exact path={appInfo.prefix + '/steps/:id'} element={<Steps app={appInfo} steps={steps} sallaLogo={sallaLogo} externalLogo={externalLogo} token={token} />} />
            <Route exact path={appInfo.prefix + '/steps'} element={<Steps app={appInfo} steps={steps} sallaLogo={sallaLogo} externalLogo={externalLogo} token={token} />} />
            <Route exact path={appInfo.prefix + '/steps/final'} element={<Final data={endPageData}/>} />
        </Routes>
    );
}

export default App;
