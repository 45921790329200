import React from 'react'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import connectIcon from '../../assets/icons/connect.svg'
export default function Wizard(props) {
    const navigate = useNavigate()
    const { app, appReady } = props
    return (
        <div className="h-screen  flex justify-center items-center">

            <div className="w-11/12 lg:w-1/3">
                <div className="bg-white lg:px-9 px-3 pt-9 pb-2 rounded-lg shadow-basic min-w-full text-center3">
                    <div className="connect-widget mb-10">
                        <div className="connect-widget__item text-center">
                            {app.logo ? <img src={app.logo} alt="Salla" /> : <Skeleton direction='rtl' width={70} height={60} style={{ margin: 'auto' }} />}
                        </div>
                        <div className="connect-widget__sep">
                            <img src={connectIcon} alt="" />
                        </div>
                        <div className="connect-widget__item text-center">

                            {app.external_logo ? <img src={app.external_logo} alt="External Logo" /> : <Skeleton direction='rtl' width={70} height={60} style={{ margin: 'auto' }} />}
                        </div>
                    </div>
                    {app.name ? <h1 className="text-center text-2xl mb-6 text-primary font-bold my-3">{app.name}</h1> : <Skeleton direction='rtl' width={200} height={30} style={{ display: 'block', margin: 'auto' }} />}
                    {appReady ?
                        <>
                            {app.description ?
                                <p className="text-gray-500 text-center text-sm"  dangerouslySetInnerHTML={{__html: app.description }}></p> :
                                <Skeleton direction='rtl' width={250} height={20} style={{ display: 'block', margin: 'auto' }} />}
                        </>
                        : <>
                            {app.app_instructions ? <p className="text-gray-500 text-center text-sm">
                                {app.app_instructions}
                            </p> : <Skeleton direction='rtl' width={250} height={20} style={{ display: 'block', margin: 'auto' }} />}
                        </>}

                    {appReady ?
                        <>
                            {app['start-button-label'] ? <button onClick={() => navigate((app.prefix + '/steps').substring(1))} className="w-full mt-10 mb-5 bg-secondary rounded px-4 py-2 text-lg text-primary tracking-wide shadow-btn font-semibold ">{app['start-button-label']} </button> : <Skeleton direction='rtl' width={300} height={35} style={{ display: 'block', margin: 'auto' }} />}
                        </>
                        : <button disabled className="w-full opacity-75 mt-10 mb-5 bg-secondary rounded px-4 py-2 text-lg text-primary tracking-wide shadow-btn font-semibold "><div className='basis-full w-full text-center'><div className="loader mx-auto"></div></div> </button>}

                </div>
            </div>
        </div>
    )
}
